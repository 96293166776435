<template>
  <div class="page">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :postData="postData"
      methods="post"
    >
      <template #headBtnSlot>
        <v-button text="导出" @click="toExport()"></v-button>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <template #searchSlot>
        <div style="display: flex; align-items: center; margin-bottom: 20px">
          <div style="font-size: 14px; margin-right: 10px">时间查询：</div>
          <v-date-picker
            v-model="datetimerange"
            type="datetimerange"
            clearable
            @change="changeDate"
          ></v-date-picker>
        </div>
      </template>
      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="remove(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  addOrUpVisPerInfoUrl,
  deleteVisPerInfoUrl,
  getVisPerInfoUrl,
  importUrl,
  getSearchWordsUrl,
  updateBusinessInfoUrl,
  deleteBusinessIncomeUrl,
  getBusinessInfosUrl,
  deleteBusinessInfoUrl,
  getBusinessIncomeUrl,
  exportBusinessInfoUrl,
  exportBusinessIncomeUrl,
  exportBusinessRevenueUrl,
  deleteBusinessRevenueUrl,
  getBusinessRevenueUrl,
  sendSmsUrl,
  getBuildingInfoUrl,
  saveLastWordsUrl,
  getLastWordsUrl,
} from "./api.js";
import { Message, MessageBox } from "element-ui";
export default {
  name: "businessPage",
  data() {
    return {
      headers: [
        // {
        //   prop: "",
        //   label: "所属区域",
        // },
        {
          prop: "taxpayerRegistrationNumber",
          label: "纳税人代码",
        },
        {
          prop: "companyName",
          label: "纳税人名称",
        },
        {
          prop: "proceedsYear",
          label: "年份",
        },
        {
          prop: "startMonth",
          label: "所属日期起",
        },
        {
          prop: "endMonth",
          label: "所属如期止",
        },
        {
          prop: "operationProceeds",
          label: "营业收入",
        },
        {
          prop: "lastOperationProceeds",
          label: "营业收入（上年同期）",
        },
        {
          prop: "dataType",
          label: "数据类型",
        },
        {
          prop: "pigeonholeCondition",
          label: "归档情况",
        },
      ],
      datetimerange: [],
      tableUrl: getBusinessIncomeUrl,
      postData: {
        startMonth: null,
        endMonth: null,
        taxpayerRegistrationNumber: null,
      },
    };
  },
  created() {
    this.postData.taxpayerRegistrationNumber =
      this.$route.query.taxpayerRegistrationNumber;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    changeDate() {
      if (this.datetimerange && this.datetimerange.length == 2) {
        this.postData.startMonth = this.datetimerange[0].format("yyyy-MM-dd");
        this.postData.endMonth = this.datetimerange[1].format("yyyy-MM-dd");
      } else {
        this.postData.startMonth = null;
        this.postData.endMonth = null;
      }
    },
    // 删除
    remove(item) {
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item.id,
          };
          this.$axios
            .post(`${deleteBusinessIncomeUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    // 导出
    toExport() {
      let belongYear;
      if (this.postData.startMonth != null) {
        belongYear = this.postData.startMonth.substring(0, 4);
      }
      let params = {
        taxpayerRegistrationNumber:
          this.$route.query.taxpayerRegistrationNumber,
        belongYear: belongYear || "",
        startMonth: this.postData.startMonth || "",
        endMonth: this.postData.endMonth || "",
      };
      this.$axios
        .get(
          `/gateway/hc-arch/buildingRegulate/exportBusinessIncome?taxpayerRegistrationNumber=${params.taxpayerRegistrationNumber}&belongYear=${params.belongYear}&startMonth=${params.startMonth}&endMonth=${params.endMonth}`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          console.log(res, "res-----");
          // 得到请求到的数据后，对数据进行处理
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          }); // 创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
          console.log(res.headers, "res.headers");
          let fileName = decodeURI(res.headers["content-disposition"]); // 设置文件名称,decodeURI：可以对后端使用encodeURI() 函数编码过的 URI 进行解码。encodeURI() 是后端为了解决中文乱码问题
          if (fileName) {
            // 根据后端返回的数据处理文件名称
            fileName = fileName.substring(fileName.indexOf("=") + 1);
          }
          console.log(fileName, "fileName");
          const link = document.createElement("a"); // 创建一个a标签
          link.download = fileName; // 设置a标签的下载属性
          link.style.display = "none"; // 将a标签设置为隐藏
          link.href = URL.createObjectURL(blob); // 把之前处理好的地址赋给a标签的href
          document.body.appendChild(link); // 将a标签添加到body中
          link.click(); // 执行a标签的点击方法
          URL.revokeObjectURL(link.href); // 下载完成释放URL 对象
          document.body.removeChild(link); // 移除a标签
        });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  height: 100%;
  box-sizing: border-box;
}
</style>
